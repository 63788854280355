import {
  SERVICES_FETCH,
  SERVICE_ERROR,
  SERVICE_FETCH,
  SERVICE_REQUEST,
} from "./ServiceTypes";

import { EdastonAPI } from "../../api";

export const FetchServices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_REQUEST,
    });

    const response = await EdastonAPI.get("service/service/");

    dispatch({
      type: SERVICES_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchService = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_REQUEST,
    });

    const response = await EdastonAPI.get(`service/service/${id}`);

    dispatch({
      type: SERVICE_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
