import { FooterPaths, MainPaths } from "../navigation/Paths";

import { NavLinkItem } from "..";
import { OrgInfo } from "../../../utils/StaticOrgInfo";
import { Text } from "../../typography";
import { colors } from "../../../assets/styles/parameters";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

// import social1 from "../../../assets/imgs/Asset-161.svg";
// import social2 from "../../../assets/imgs/Asset-162.svg";

// const Social = [social1, social2];

const Footer = () => {
  return (
    <BaseFooter>
      <Main>
        <div>
          <address>
            <Text>{OrgInfo.address}</Text>
            <a href={`mailto:${OrgInfo.email}`}>{OrgInfo.email}</a>
            <a href={`tel:+${OrgInfo.phone}`}>{OrgInfo.phone}</a>
          </address>
        </div>
        <ul>
          <NavLinkItem path="/" name="home" />
          {MainPaths.map((path, index) => {
            return (
              <NavLinkItem key={index} path={path.path} name={path.name} />
            );
          })}
          {FooterPaths.map((path, index) => {
            return (
              <NavLinkItem key={index} path={path.path} name={path.name} />
            );
          })}
        </ul>

        {/* <BaseSocial>
          {Social.map((s, index) => {
            return (
              <li key={index}>
                <img src={s} alt="" />
              </li>
            );
          })}
        </BaseSocial> */}
      </Main>
      <Sub>
        <p>
          &copy;
          <span className="year"> {new Date().getFullYear()} </span>Edaston -
          All Rights Reserved
        </p>
        <ul>
          <NavLinkItem
            path="/policy"
            name="Legal - Terms of Use, Privacy Policy"
          />
        </ul>
      </Sub>
    </BaseFooter>
  );
};

export default Footer;

const BaseFooter = styled.footer`
  padding: 3rem 4rem 0.7rem;
  background-color: ${colors.black};

  * {
    color: ${colors.white};
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 3rem 1rem 0.7rem;
  }
`;

const Main = styled.div`
  display: flex;
  justify-content: space-between;

  h5 {
    margin-right: 2rem;
  }

  address {
    font-style: normal;

    a {
      margin: 0.5rem 0;
      display: block;
    }
  }

  & > ul {
    display: flex;

    li {
      margin-left: 5rem;
    }
  }

  @media screen and (min-width: ${responsiveness.desktop1}) {
    max-width: ${responsiveness.maxWidth};
    margin: auto;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    flex-direction: column;

    ul {
      flex-direction: column;
      margin-top: 2rem;

      li {
        margin: 0 0 2rem 0;
      }
    }
  }
`;

const Sub = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  p,
  li {
    font-size: 0.7rem;
  }

  ul {
    display: flex;
    justify-content: space-between;

    li {
      margin-left: 5rem;
    }
  }

  @media screen and (min-width: ${responsiveness.desktop1}) {
    max-width: ${responsiveness.maxWidth};
    margin: 3rem auto 0;
  }
`;

// const BaseSocial = styled.div`
//   display: flex;

//   li {
//     margin-right: 1rem;
//     cursor: pointer;
//     height: min-content;

//     img {
//       width: 18px;
//       height: 18px;
//     }
//   }

//   li:last-child {
//     margin-right: 0;
//   }
// `;
