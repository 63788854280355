import {
  SERVICES_FETCH,
  SERVICE_ERROR,
  SERVICE_FETCH,
  SERVICE_REQUEST,
} from "./ServiceTypes";

export const ServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_REQUEST:
      return { ...state, loading: true };
    case SERVICES_FETCH:
      return { ...state, loading: false, services: action.payload };
    case SERVICE_FETCH:
      return { ...state, loading: false, service: action.payload };
    case SERVICE_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
