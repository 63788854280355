import { Text, Title } from "../../typography";

import { Container } from "../../layout";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

const PageHeader = ({ title, sectionHeader, children, ...otherProps }) => {
  return (
    <BaseHeader {...otherProps}>
      <Container width={80} margin="none">
        {title && <Text>{title}</Text>}
        {sectionHeader && <Title number="1">{sectionHeader}</Title>}
        {children}
      </Container>
    </BaseHeader>
  );
};

export default PageHeader;

const BaseHeader = styled.header`
  padding: 4rem 4rem 2rem;

  @media screen and (min-width: ${responsiveness.maxWidth}) {
    max-width: ${responsiveness.maxWidth};
    margin: auto;
    padding: 4rem 0 2rem;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 3rem 1rem;
  }
`;
