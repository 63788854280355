import { Span, Text, Title } from "../typography";
import { colors, fontSize, shadow } from "../../assets/styles/parameters";

import styled from "styled-components";

export const ContentWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div``;

export const Cards = ({
  version,
  img,
  imgDescription,
  title,
  featuredText,
  body,
  path,
  ...otherProps
}) => {
  switch (version) {
    case 2:
      return (
        <CardTwo {...otherProps}>
          <img src={img} alt={imgDescription} />
          <div>
            <Span color="gray">Lorem, ipsum dolor. 12/23/22</Span>
            <Title number={5}>Lorem, ipsum dolor.</Title>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit necessitatibus saepe amet harum. Voluptatibus sed
              aut delectus dignissimos cum repellendus placeat possimus eos est
              dolores a, magni repellat unde! Modi!
            </Text>
          </div>
        </CardTwo>
      );
    default:
      return (
        <CardDefault {...otherProps}>
          <span />
          <Title number={4}>Lorem, ipsum dolor.</Title>
          <Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit,
            perferendis! Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Architecto, beatae?
          </Text>
        </CardDefault>
      );
  }
};

const BaseCard = styled.div`
  padding: 1.5rem;
  height: 400px;
  border-radius: 5px;
  box-shadow: ${shadow.light};
  transition: all 0.3s ease-in;
  position: relative;
  overflow: hidden;
  margin: 3rem 0;
  width: 100%;

  &:hover {
    cursor: pointer;
    box-shadow: ${shadow.dark};
    background: ${colors.black};

    * {
      color: ${colors.white};
    }

    span {
      background-color: ${colors.black};
    }
  }
`;

// const CardOne = styled(BaseCard)`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   overflow: hidden;

//   h2 {
//     overflow: hidden;
//     white-space: nowrap;
//   }
// `;

const CardTwo = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
  }

  & > div {
    position: absolute;
    background: ${colors.white};
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 1rem;
    z-index: 100;

    h5 {
      margin: 0.5rem 0 0.5rem 0;
    }

    p {
      display: none;
    }

    span {
      font-size: ${fontSize.small};
    }
  }

  &:hover {
    & > div {
      * {
        color: ${colors.black};
      }
      span {
        background: ${colors.white};
        color: ${colors.gray600};
      }
      p {
        display: block;
      }
    }
  }
`;

const CardDefault = styled(BaseCard)`
  & > span {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${colors.red};
    color: ${colors.red};
    margin-bottom: 2rem;
  }
`;
