import { ImageItem } from "./../images";
import { LoaderCircle } from "./LoaderCircle";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const ImageItemPositions = [
  {
    right: 9,
    top: 20,
    width: "112px",
    height: "305px",
  },
  {
    left: 0,
    right: 9,
    top: 61,
    bottom: 0,
    width: "112px",
    height: "260px",
  },
  {
    right: 18.2,
    top: 35,
    bottom: 0,
    width: "207px",
    height: "122px",
  },
  {
    right: 18.2,
    top: 53,
    width: "207px",
    height: "405px",
  },
  {
    right: 34,
    top: 40,
    width: "183px",
    height: "325px",
  },
];

const Loader = () => {
  return (
    <BaseLoader>
      <LoaderCircle />
      {ImageItemPositions.map((position, index) => {
        return <ImageItem key={index} position={position} />;
      })}
    </BaseLoader>
  );
};

export default Loader;

const BaseLoader = styled.header`
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &::before,
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;

    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    z-index: 10002;
    transition: all 0.5s ease-in-out;
  }

  svg {
    top: 20%;
  }
`;
