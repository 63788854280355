import styled, { css } from "styled-components";

import { responsiveness } from "../../../assets/styles/parameters";

const Container = ({ width, margin, children, ...otherProps }) => {
  return (
    <BaseContainer width={width} margin={margin} {...otherProps}>
      {children}
    </BaseContainer>
  );
};

export default Container;

const BaseContainer = styled.div`
  width: ${({ width }) => (width ? `${width}%` : "100%")};

  ${({ margin }) => {
    switch (margin) {
      case "none":
        return css`
          margin: none;
        `;
      default:
        return css`
          margin: auto;
        `;
    }
  }};

  @media screen and (min-width: ${responsiveness.maxWidth}) {
    max-width: ${responsiveness.maxWidth};
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
  }
`;
