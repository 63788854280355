import { Container, Grid, Section } from "../../components/layout";
import { Header, Main } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import AboutServices from "../about/AboutServices";
import { Button } from "../../components/button";
import HomeCaseList from "./HomeCaseList";
import { Meta } from "../../components/meta";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";

const HomePage = () => {
  return (
    <>
      <Meta
        title="Home"
        description="Home page of Edaston. You will find information about how we help our clients and partners achieve
        their business and personal goals by redesigning or designing your websites and building other web applications."
      />
      <Header />
      <Main>
        <BaseContent background="black">
          <Container width={60}>
            <Title number="3">Are you a small business owner feeling...</Title>
            <Text>
              LOST … because you don't have the technical knowledge to build a
              site yourself.
            </Text>
            <Text>
              TIME-CRUNCHED… since daily tasks leaves little room for website
              development.
            </Text>
            <Text>
              UNSEEN… as your current website fails to attract new clients and
              showcase your true potential.
            </Text>
          </Container>
        </BaseContent>
        <BaseContent>
          <Container>
            <Title number="3">Get Found, Get Leads, Get Results!</Title>
            <Text>
              We are a passionate team of creative minds and technical experts
              who understand the unique challenges faced by small businesses in
              the digital landscape.
            </Text>
            <Button type="link" to="/about">
              Learn More
            </Button>
          </Container>
        </BaseContent>
        <BaseContent>
          <Container>
            <Grid col={7}>
              <Title number="3">Website creation process</Title>
              <div>
                <Text>
                  We'll guide you through the website creation process, freeing
                  up your time and technical anxieties. We will help you craft a
                  website that showcases your true potential and brings in the
                  clients you deserve.
                </Text>
                <Text>
                  Whether you're a small business owner or an entrepreneur
                  looking to showcase your skills, a well-designed and
                  user-friendly website is crucial for attracting clients,
                  establishing credibility, and achieving your business goals.
                </Text>
                <Text>
                  Let's be honest - building a website can be a complex and
                  time-consuming process. Without the right expertise, you might
                  end up with:
                </Text>
                <ul>
                  <li>
                    <span> A confusing and outdated website:</span> This can
                    drive potential customers away and damage your brand
                    reputation.
                  </li>
                  <li>
                    <span> A lack of functionality:</span> Missing essential
                    features like contact forms or online booking can frustrate
                    visitors and hinder lead generation.
                  </li>
                  <li>
                    <span> Poor search engine optimization (SEO): </span> Your
                    website becomes invisible in search results, making it
                    difficult for potential customers to find you online.
                  </li>
                </ul>
              </div>
            </Grid>
          </Container>
        </BaseContent>
        <AboutServices />
        <HomeCaseList />
      </Main>
    </>
  );
};

export default HomePage;

const BaseContent = styled(Section)`
  display: grid;
  gap: 2rem;

  h5 {
    margin-top: 1rem;
  }

  ul {
    margin-top: 1rem;

    li {
      list-style: circle;
      margin-left: 2rem;
      margin-bottom: 0.5rem;

      span {
        font-weight: bold;
      }
    }
  }
  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;
