import { closeMobileNav } from "../../../redux/navigation/mobileNavActions";
import { connect } from "react-redux";

const Main = ({ children, closeMobileNav, ...otherProps }) => {
  return (
    <main onClick={closeMobileNav} {...otherProps}>
      {children}
    </main>
  );
};

export default connect(null, { closeMobileNav })(Main);
