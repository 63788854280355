import {
  TERMS_FETCH,
  TERM_ERROR,
  TERM_FETCH,
  TERM_REQUEST,
} from "./TermsTypes";

import { EdastonAPI } from "../../api";

export const FetchTerms = () => async (dispatch) => {
  try {
    dispatch({
      type: TERM_REQUEST,
    });

    const response = await EdastonAPI.get("organization/term/");

    dispatch({
      type: TERMS_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TERM_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchTerm = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TERM_REQUEST,
    });

    const response = await EdastonAPI.get(`organization/term/${id}`);

    dispatch({
      type: TERM_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TERM_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
