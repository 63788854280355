import { CLOSE_NAV, SHOW_NAV } from "./mobileNavTypes";

export const openMobileNav = () => (dispatch) => {
  dispatch({
    type: SHOW_NAV,
  });
};

export const closeMobileNav = () => (dispatch) => {
  dispatch({
    type: CLOSE_NAV,
  });
};
