import { IoCloseSharp, IoMenuSharp } from "react-icons/io5";
import {
  closeMobileNav,
  openMobileNav,
} from "../../../redux/navigation/mobileNavActions";
import { fontSize, responsiveness } from "../../../assets/styles/parameters";

import { Link } from "react-router-dom";
import { MainPaths } from "./Paths";
import NavigationLink from "./NavigationLink";
import { Text } from "../../typography";
import { connect } from "react-redux";
import logo from "../../../assets/imgs/Logo_3.svg";
import styled from "styled-components";

const Navigation = (props) => {
  const onShowNav = () => {
    props.openMobileNav();
  };

  return (
    <BaseNav>
      <div>
        <BaseLogo>
          <Text>
            <Link to="/" onClick={closeMobileNav()}>
              <img src={logo} alt="" />
            </Link>
          </Text>
        </BaseLogo>
        <MobileNav onClick={onShowNav}>
          {props.mobile ? (
            <IoCloseSharp onClick={openMobileNav} />
          ) : (
            <IoMenuSharp onClick={closeMobileNav} />
          )}
        </MobileNav>
      </div>
      <NavigationLink paths={MainPaths} open={props.mobile} />
    </BaseNav>
  );
};

const mapStateToProps = (state) => {
  return { mobile: state.mobile.open };
};

export default connect(mapStateToProps, { openMobileNav, closeMobileNav })(
  Navigation
);

const BaseNav = styled.nav`
  display: flex;
  padding: 0.2rem 4rem;
  width: 100%;
  align-items: center;
  font-size: ${fontSize.nav} !important;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;

    & > div {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const BaseLogo = styled.div`
  font-size: ${fontSize.h5};
  border-top: 1px solid transparent;
  margin: 0.9rem 1rem 0 0;
  padding: 0;

  a {
    display: flex;
    align-items: center;

    img {
      width: 100px;
      margin-right: 0.5rem;
    }
  }
`;

const MobileNav = styled.div`
  display: none;

  svg {
    font-size: 2rem;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: block;
  }
`;
