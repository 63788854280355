import { CLOSE_NAV, SHOW_NAV } from "./mobileNavTypes";

export const mobileNavReducer = (
  state = {
    open: false,
  },
  action
) => {
  switch (action.type) {
    case SHOW_NAV:
      return {
        ...state,
        open: !state.open,
      };
    case CLOSE_NAV:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
