import { Container, Main, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import { Meta } from "../../components/meta";
import StartProjectForm from "./StartProjectForm";

const StartProjectPage = () => {
  return (
    <>
      <Meta
        title="Start Project"
        description="Begin your journey and transform your ideas into powerful digital projects."
      />
      <Main>
        <Section>
          <Container width="70" margin="auto">
            <Title number="1">Ready to start your project!</Title>
            <Text>
              Complete the form with your project details, and our team will
              contact you within 24 hours to schedule time for one-on-one
              consultation.
              <br /> <br /> <br />
            </Text>
            <StartProjectForm />
          </Container>
        </Section>
      </Main>
    </>
  );
};

export default StartProjectPage;
