import "react-toastify/dist/ReactToastify.css";

import { Footer, Navigation } from "./components/layout";

import { BrowserRouter } from "react-router-dom";
import { CallToAction } from "./components/cta";
import { CalltoactionBtn } from "./components/cta";
import { Cookie } from "./components/banner";
import { GlobalStyles } from "./assets/styles/globalstyles";
import { Router } from "./Router";
import ScrollToTop from "./utils/ScrollToTop";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <ScrollToTop />
        <Navigation />
        <Cookie />
        <Router />
        <CallToAction />
        <CalltoactionBtn />
        <Footer />
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default App;
