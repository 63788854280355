import {
  CASES_FETCH,
  CASE_ERROR,
  CASE_FETCH,
  CASE_REQUEST,
  CLIENTS_FETCH,
  CLIENT_ERROR,
  CLIENT_REQUEST,
} from "./CaseTypes";

import { EdastonAPI } from "../../api";

export const FetchCases = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_REQUEST,
    });

    const response = await EdastonAPI.get("works/case/");

    dispatch({
      type: CASES_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CASE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchCase = (slug) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_REQUEST,
    });

    const response = await EdastonAPI.get(`works/case/${slug}/`);

    dispatch({
      type: CASE_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CASE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FetchClients = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_REQUEST,
    });

    const response = await EdastonAPI.get("works/client/");

    dispatch({
      type: CLIENTS_FETCH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
