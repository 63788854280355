import { Container, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

const AboutCareer = () => {
  return (
    <Section>
      <Container width={60}>
        <Title number="3">Join Our Team</Title>
        <Text>
          Interested in working with us, please email us at jobs@edaston.com and
          include in the subject title your first and last name. In the body of
          your email, include a link to your website portfolio and a brief
          description of yourself and why you want to work with Edaston. If you
          want to freelance for us, include your hourly rate and availability.
        </Text>
      </Container>
    </Section>
  );
};

export default AboutCareer;
