import { Container, Grid, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";

const AboutServices = () => {
  return (
    <BaseContent background="black">
      <Title number="5">Services</Title>

      <Container>
        <Grid col={3}>
          <ServiceCard>
            <div>
              <Title number="4">Strategy</Title>
              <Text width={90}>
                We start by understanding your goals for the project. We then
                develop systems to achieve these objectives and overcome
                challenges.
              </Text>
            </div>
            <div>
              <Text>Research</Text>
              <Text>Analysis</Text>
            </div>
          </ServiceCard>
          <ServiceCard>
            <div>
              <Title number="4">Design</Title>
              <Text width={90}>
                We use insights from our strategy sessions to craft functional
                and visual solutions that meet your goals.
              </Text>
            </div>
            <div>
              <Text>Wireframing</Text>
              <Text>User Testing</Text>
              <Text>User Interface Designs</Text>
            </div>
          </ServiceCard>
          <ServiceCard>
            <div>
              <div>
                <Title number="4">Development</Title>
                <Text width={90}>
                  We identify and build critical website functions your users
                  need for your business to be successful online.
                </Text>
              </div>
              <div>
                <Text>APIs</Text>
                <Text>Management Systems</Text>
                <Text>Web Application Development</Text>
                <Text>Website Maintenance and Management</Text>
              </div>
            </div>
          </ServiceCard>
        </Grid>
      </Container>
    </BaseContent>
  );
};

export default AboutServices;

const BaseContent = styled(Section)`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: 2rem;

  h5 {
    margin-top: 1rem;
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  margin-bottom: 3rem;
`;
