import {
  CustomSelect,
  CustomTextArea,
  FormContainer,
  Input,
  Label,
} from "../../components/forms";
import { Field, Form } from "react-final-form";

import { Button } from "../../components/button";
import { Component } from "react";
import { CreateProject } from "../../redux/contact/ContactActions";
import { Grid } from "../../components/layout";
import { Title } from "../../components/typography";
import { connect } from "react-redux";

class StartProjectForm extends Component {
  onSubmitValues = (values) => {
    this.props.CreateProject(values);
  };

  render() {
    return (
      <>
        <Form
          onSubmit={this.onSubmitValues}
          render={({ handleSubmit, submitting, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <Title number="5">Tell us about your project?</Title>

                <div>
                  <Field
                    name="project_description"
                    type="text"
                    component={CustomTextArea}
                    label="Briefly describe your project."
                    placeholder="My project is about..."
                    required
                  />
                </div>
                <Grid>
                  <div>
                    <Label>What is your estimated timeline?</Label>
                    <Field name="timeline" component={CustomSelect} required>
                      <option></option>
                      <option value="3 months">3 months</option>
                      <option value="6 months">6 months</option>
                      <option value="9 months">9 months</option>
                      <option value="12+ months">12+ months</option>
                    </Field>
                  </div>
                  <div>
                    <Label>What is your available budget?</Label>
                    <Field name="budget" component={CustomSelect} required>
                      <option></option>
                      <option value="$5-$10k">$5-$10k</option>
                      <option value="$10-$50k">$10-$50k</option>
                      <option value="$50-$250k">$50-$250k</option>
                      <option value="$250k+">$250k+</option>
                    </Field>
                  </div>
                </Grid>
              </FormContainer>

              <FormContainer>
                <Title number="5">How can we contact you?</Title>
                <Grid>
                  <div>
                    <Field
                      name="first_name"
                      type="text"
                      component={Input}
                      label="First Name"
                      placeholder="My first name is..."
                      required
                    />
                  </div>
                  <div>
                    <Field
                      name="last_name"
                      type="text"
                      component={Input}
                      label="Last Name"
                      placeholder="My last name is..."
                      required
                    />
                  </div>
                </Grid>
                <Grid>
                  <div>
                    <Field
                      name="phone"
                      type="tel"
                      component={Input}
                      label="Phone Number"
                      placeholder="You can call you on..."
                      required
                    />
                  </div>
                  <div>
                    <Field
                      name="email"
                      type="email"
                      component={Input}
                      label="Email"
                      placeholder="You can email me at..."
                      required
                    />
                  </div>
                </Grid>
              </FormContainer>

              <Button
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  invalid ||
                  (this.props.start.start &&
                    this.props.start.start.status === "success")
                }
              >
                {pristine ? "Complete Form" : "Submit Form"}
                {submitting && "Submitting"}
              </Button>
            </form>
          )}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { start: state.start };
};

export default connect(mapStatetoProps, { CreateProject })(StartProjectForm);
