import {
  AboutPage,
  CapabilityPage,
  CaseDetailPage,
  CaseStudiesPage,
  ContactPage,
  HomePage,
  LegalPage,
  Page404,
  PoliciesDetailPage,
  StartProject,
} from "./pages";
import { Route, Routes } from "react-router-dom";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/works" element={<CaseStudiesPage />} />
      <Route path="/works/:slug" element={<CaseDetailPage />} />
      <Route path="/start" element={<StartProject />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/capability-deck" element={<CapabilityPage />} />
      <Route path="/policy" element={<LegalPage />} />
      <Route path="/policy/:slug" element={<PoliciesDetailPage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Router;
