import {
  CapabilityReducer,
  ContactReducer,
  StartProjectReducer,
  SubscribeReducer,
} from "./contact/ContactReducer";
import { caseReducer, clientReducer } from "./cases/CaseReducer";

import { OrgReducer } from "./organization/orgReducer";
import { ServiceReducer } from "./service/ServicetReducer";
import { TermsReducer } from "./terms/TermsReducer";
import { combineReducers } from "redux";
import { mobileNavReducer } from "./navigation/mobileNavReducer";

export default combineReducers({
  org: OrgReducer,
  service: ServiceReducer,
  contact: ContactReducer,
  subscribe: SubscribeReducer,
  start: StartProjectReducer,
  lead: CapabilityReducer,
  legal: TermsReducer,
  works: caseReducer,
  clients: clientReducer,
  mobile: mobileNavReducer,
});
