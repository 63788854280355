import {
  CAPABILITY_CREATE,
  CAPABILITY_ERROR,
  CAPABILITY_REQUEST,
  CONTACT_CREATE,
  CONTACT_ERROR,
  CONTACT_REQUEST,
  START_PROJECT_CREATE,
  START_PROJECT_ERROR,
  START_PROJECT_REQUEST,
  SUBSCRIBE_CREATE,
  SUBSCRIBE_ERROR,
  SUBSCRIBE_REQUEST,
} from "./ContactTypes";

import { EdastonAPI } from "../../api";

export const CreateContact = (formValues) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACT_REQUEST,
    });

    const response = await EdastonAPI.post("contacts/contact/", formValues);

    dispatch({
      type: CONTACT_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateSubscriber = (formValues) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUBSCRIBE_REQUEST,
    });

    const response = await EdastonAPI.post("contacts/subscribe/", formValues);

    dispatch({
      type: SUBSCRIBE_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateCapability = (formValues) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAPABILITY_REQUEST,
    });

    const response = await EdastonAPI.post("contacts/lead/", formValues);

    dispatch({
      type: CAPABILITY_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CAPABILITY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateProject = (formValues) => async (dispatch, getState) => {
  try {
    dispatch({
      type: START_PROJECT_REQUEST,
    });

    const response = await EdastonAPI.post("contacts/start/", formValues);

    dispatch({
      type: START_PROJECT_CREATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: START_PROJECT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
