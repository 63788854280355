import { Link } from "react-router-dom";
import img from "../../assets/imgs/Logo_2.svg";
import img2 from "../../assets/imgs/Asset-11.png";
import { shadow } from "../../assets/styles/parameters";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const CalltoactionBtn = () => {
  const location = useLocation();

  return (
    <BaseCTA as={Link} to="/start" location={location}>
      <img src={img} alt="Edaston Logo" />
      <p> Start Project</p>
    </BaseCTA>
  );
};

export default CalltoactionBtn;

const BaseCTA = styled.div`
  background: url(${img2}) no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 100;
  bottom: 6%;
  right: 0;
  padding: 5px 20px;
  background: linear-gradient(
    45deg,
    hsla(208, 54%, 19%, 1) 8%,
    hsla(205, 71%, 60%, 1) 100%
  );
  border-radius: 5px 0 0 5px;
  display: ${({ location }) =>
    location.pathname === "/start" ? "none" : "flex"};
  box-shadow: ${shadow.dark};
  opacity: 1;
  transition: all 1s ease-out;

  img {
    width: 30px;
    margin-right: 0.5rem;
    object-fit: contain;
  }

  p {
    display: none;
    margin: 0;
    padding: 0;
    color: white;
  }

  &:hover {
    cursor: pointer;

    p {
      display: block;
    }
  }
`;
