import { Container, Grid, Main, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import ContactForm from "./ContactForm";
import { Meta } from "../../components/meta";
import { OrgInfo } from "../../utils/StaticOrgInfo";
import styled from "styled-components";

const ContactPage = () => {
  return (
    <>
      <Meta
        title="Contact"
        description="Contact page for you to send inquiries to Edaston regarding its services in strategy, web development, and design."
      />
      <Main>
        <Section>
          <Container>
            <Title number="1">Have a question?</Title>
            <Grid>
              <div>
                <InfoCard>
                  <Title number="5" width="80">
                    Speak to a member of our team
                  </Title>
                  <Text width="80">
                    Call: <a href={`tel:+${OrgInfo.phone}`}>{OrgInfo.phone}</a>
                    <br />
                    Email:{" "}
                    <a href={`mailto:${OrgInfo.email}`}>{OrgInfo.email}</a>
                    <br />
                    {OrgInfo.address}
                  </Text>
                </InfoCard>
              </div>
              <ContactForm />
            </Grid>
          </Container>
        </Section>
      </Main>
    </>
  );
};

export default ContactPage;

const InfoCard = styled.div`
  margin-bottom: 2rem;
`;
