import { applyMiddleware, createStore } from "redux";

import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { persistReducer } from "redux-persist";
import reducer from "./rootReducer";
import reduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

// import { createLogger } from "redux-logger";


const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducer);

// const logger = createLogger({ duration: true });

const store = createStore(persistedReducer, applyMiddleware(reduxThunk));

export default store;
