import { Container, Main, PageHeader, Section } from "../../components/layout";

import { FetchTerm } from "../../redux/terms/TermsActions";
import { Meta } from "../../components/meta";
import Moment from "react-moment";
import { Text } from "../../components/typography";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TermsPageDetail = (props) => {
  const { FetchTerm, Term } = props;
  const { id } = useLocation().state;

  useEffect(() => {
    FetchTerm(id);
  }, [FetchTerm, id]);

  const { term, loading } = Term;

  if (loading || !term) {
    return (
      <Section>
        <Container>
          <Text>Loading</Text>
        </Container>
      </Section>
    );
  }
  const { title, body, updated } = term;

  return (
    <>
      <Meta title={title} />
      <PageHeader
        title={title}
        sectionHeader=" We respect and are committed to preserving the privacy of users of
          our website."
      >
        Last updated on <Moment date={updated} format="MM/DD/YYYY" />
      </PageHeader>
      <Main>
        <Section>
          <Container width="70">{parse(body)}</Container>
        </Section>
      </Main>
    </>
  );
};

const mapStateToProps = (state) => {
  return { Term: state.legal };
};

export default connect(mapStateToProps, { FetchTerm })(TermsPageDetail);
