import styled from "styled-components";

const ImageItem = ({ position, background }) => {
  return <BaseImageItem position={position} background={background} />;
};

export default ImageItem;

const BaseImageItem = styled.div`
  border-radius: 10px;
  height: 300px;
  background: url(${({ background }) => background}) no-repeat;
  background-size: cover;
`;
