import { Container, Section } from "../../components/layout";

import { CaseCard } from "../../components/cases";
import { Component } from "react";
import { FetchCases } from "../../redux/cases/CaseActions";
import { connect } from "react-redux";

class CaseList extends Component {
  componentDidMount() {
    this.props.FetchCases();
  }

  render() {
    const { cases } = this.props;
    if (cases.loading) {
      return <Section>Loading</Section>;
    }

    return (
      <>
        {cases.cases && cases.cases.length > 0 && (
          <Section>
            <Container>
              {cases.cases.map(
                (
                  {
                    title,
                    headline,
                    slug,
                    featured_image,
                    featured_image_alt,
                    card,
                  },
                  index
                ) => {
                  return (
                    <CaseCard
                      card={card}
                      id={1}
                      key={index}
                      title={title}
                      slug={slug}
                      headline={headline}
                      image={featured_image}
                      imageDescription={featured_image_alt}
                    />
                  );
                }
              )}
            </Container>
          </Section>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { cases: state.works };
};

export default connect(mapStatetoProps, { FetchCases })(CaseList);
