import { Container, Main, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import { CaseCard } from "../../components/cases";
import { Component } from "react";
import { FetchService } from "../../redux/service/ServiceActions";
import { connect } from "react-redux";
import img from "../../assets/imgs/web-dev.png";
import img3 from "../../assets/imgs/gym.jpg";
import { shadow } from "../../assets/styles/parameters";
import styled from "styled-components";

const CasesData = [
  {
    slug: "/",
    title: "Young United Mothers",
    headline:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur repellendus sint quidem quae tenetur asperiores nisi natus voluptatem quaerat corrupti at laborum accusantium, doloremque atque. Laborum cum magni illo sequi!",
  },
  {
    slug: "/",
    title: "IDPDNow",
    headline:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur repellendus sint quidem quae tenetur asperiores nisi natus voluptatem quaerat corrupti at laborum accusantium, doloremque atque. Laborum cum magni illo sequi!",
  },
];

class ServiceDetailPage extends Component {
  render() {
    return (
      <Main>
        <Section>
          <Container>
            <Title number={1}>Development</Title>
            <Text width={70}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
              fuga esse. Eligendi perspiciatis amet sit saepe hic nobis porro
              quis dolores sint! In aliquid quam reiciendis atque quaerat
              temporibus nobis!
            </Text>
            <Text width={70}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
              fuga esse. Eligendi perspiciatis amet sit saepe hic nobis porro
              quis dolores sint! In aliquid quam reiciendis atque quaerat
              temporibus nobis!
            </Text>
            <ImageContainer>
              <img src={img} alt="" />
              <img src={img3} alt="" />
            </ImageContainer>
          </Container>
        </Section>
        <Section>
          <Container>
            <Title number={4}>Why Edaston</Title>
            <Text width={70}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
              fuga esse. Eligendi perspiciatis amet sit saepe hic nobis porro
              quis dolores sint! In aliquid quam reiciendis atque quaerat
              temporibus nobis!
            </Text>
            <Text width={70}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
              fuga esse. Eligendi perspiciatis amet sit saepe hic nobis porro
              quis dolores sint! In aliquid quam reiciendis atque quaerat
              temporibus nobis!
            </Text>
          </Container>
        </Section>
        <Section>
          <Container>
            <Title number={4}>Here are some of our works</Title>
            {CasesData &&
              CasesData.map(({ title, slug, headline }, index) => {
                return (
                  <CaseCard
                    key={index}
                    card={index + 3}
                    title={title}
                    headline={headline}
                    image={img3}
                    slug={slug}
                  />
                );
              })}
          </Container>
        </Section>
      </Main>
    );
  }
}

const mapStatetoProps = (state) => {
  return { service: state.service, services: state.services };
};

export default connect(mapStatetoProps, { FetchService })(ServiceDetailPage);

const ImageContainer = styled.div`
  position: relative;
  height: 90vh;
  margin-top: 3rem;

  img {
    position: absolute;
    border-radius: 10px;
    display: inline-block;
    object-fit: cover;
  }

  img:nth-child(1) {
    width: 70%;
    z-index: 10;
    box-shadow: ${shadow.light};
  }

  img:nth-child(2) {
    width: 70%;
    height: 80%;
    z-index: 100;
    right: 0;
    top: 30%;
    box-shadow: ${shadow.dark};
  }
`;
