import { CustomTextArea, Input } from "../../components/forms";
import { Field, Form } from "react-final-form";

import { Button } from "../../components/button";
import { Component } from "react";
import { CreateCapability } from "../../redux/contact/ContactActions";
import { Grid } from "../../components/layout";
import { connect } from "react-redux";

class CapabilityForm extends Component {
  onSubmitValues = (values) => {
    this.props.CreateCapability(values);
  };

  render() {
    return (
      <>
        <Form
          onSubmit={this.onSubmitValues}
          render={({ handleSubmit, submitting, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Grid>
                <div>
                  <Field
                    name="first_name"
                    type="text"
                    component={Input}
                    label="First Name"
                    placeholder="My first name is ..."
                  />
                </div>
                <div>
                  <Field
                    name="last_name"
                    type="text"
                    component={Input}
                    label="Last Name"
                    placeholder="My last name is ..."
                  />{" "}
                </div>
              </Grid>
              <Grid>
                <div>
                  <Field
                    name="phone"
                    type="tel"
                    component={Input}
                    label="Phone Number"
                    placeholder="You can contact me on ..."
                  />
                </div>
                <div>
                  <Field
                    name="email"
                    type="email"
                    component={Input}
                    label="Email Address"
                    placeholder="Email me at ..."
                  />
                </div>
              </Grid>
              <div>
                <Field
                  name="company"
                  type="text"
                  component={Input}
                  label="Company, Group or Organization Name"
                  placeholder="My company, group, or organization name is ..."
                />
              </div>
              <div>
                <Field
                  name="comments"
                  type="text"
                  component={CustomTextArea}
                  label="Comments"
                  placeholder="Add a comment with your request..."
                />
              </div>
              <Button
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  invalid ||
                  (this.props.lead.lead &&
                    this.props.lead.lead.status === "success")
                }
              >
                {pristine ? "Complete Form" : "Submit Form"}
                {submitting && "Submitting..."}
              </Button>
            </form>
          )}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { lead: state.lead };
};

export default connect(mapStatetoProps, { CreateCapability })(CapabilityForm);
