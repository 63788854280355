import { ORG_ERROR, ORG_FETCH, ORG_REQUEST } from "./orgTypes";

export const OrgReducer = (state = {}, action) => {
  switch (action.type) {
    case ORG_REQUEST:
      return { ...state, loading: true };
    case ORG_FETCH:
      return { ...state, loading: false, edaston: action.payload };
    case ORG_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
