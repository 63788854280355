import { colors, responsiveness } from "../../../assets/styles/parameters";
import styled, { css } from "styled-components";

import { NavLink } from "react-router-dom";
import { closeMobileNav } from "../../../redux/navigation/mobileNavActions";
import { connect } from "react-redux";

const NavLinkItem = ({ name, path, highlight, closeMobileNav }) => {
  return (
    <BaseListItem onClick={() => closeMobileNav()}>
      <NavLink to={path} highlight={highlight}>
        {name}
      </NavLink>
    </BaseListItem>
  );
};

export default connect(null, { closeMobileNav })(NavLinkItem);

const BaseListItem = styled.li`
  margin-left: 2rem;

  a {
    ${(props) => {
      switch (props.children.props.highlight) {
        case "true":
          return css`
            color: ${colors.black};
          `;
        default:
          return css`
            color: none;
          `;
      }
    }};
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin: 3rem 0 0 0;
  }
`;
