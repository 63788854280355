import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title} | Edaston</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keyword" content={keywords} />}
      {title && <meta property="og:title" content={`${title} | Edaston`} />}
      {description && <meta property="og:description" content={description} />}
      {title && <meta name="twitter:title" content={`${title} | Edaston`} />}
      {description && <meta name="twitter:description" content={description} />}
    </Helmet>
  );
};

Meta.defaultProps = {
  title:
    "Edaston | Strategy, Web Development, Web Design in Washington, DC area",
  description:
    "Edaston is a strategy, web development, and web design in Washington, DC area focused on helping nonprofit and businesses achieve meaningful results.",
};

export default Meta;
