import { Span, Text } from "../typography";
import { colors, responsiveness } from "../../assets/styles/parameters";

import styled from "styled-components";

export const FormContainer = styled.div`
  margin-bottom: 1rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    margin-bottom: 2rem;
  }
`;

export const CheckBoxFormContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  * {
    padding: 0 !important;
    margin: 0 !important;
  }

  p {
    margin-left: 0.5rem !important;
  }
`;
export const BaseInput = styled.input`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0.3rem 0.3rem 0.3rem 0;
  margin: 0.5rem 0;
  border: 1px solid transparent;
  border-bottom: ${`1px solid black`};
  border-radius: 0px;
  width: 100%;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    border-bottom: ${`1px solid ${colors.lightblue}`};
  }

  &::placeholder {
    font-size: 0.9rem;
  }
`;

const BaseLabel = styled.label`
  color: ${colors.gray100};
  display: flex;
  align-items: center;
  position: relative;

  p {
    padding: 0;
    margin: 0.5rem 0 0 0;
  }

  & > span {
    position: absolute;
    top: -10;
    left: 0;
    font-size: 0.5rem;
    color: red;
    height: min-content;
  }
`;

export const Label = ({ children, text, entity, ...otherProps }) => {
  return (
    <BaseLabel style={entity && { paddingLeft: "0.5rem" }} {...otherProps}>
      <Text color={colors.gray700}>{children || text}</Text>
      <Span>{entity}</Span>
    </BaseLabel>
  );
};

const TextInput = styled(BaseInput)``;
const FormControl = styled.div``;

export const Input = (props) => {
  const { input, meta, children, label, entity, ...otherProps } = props;
  const { touched, error } = meta;

  return (
    <FormControl>
      {label && <Label text={label} entity={entity} />}
      <TextInput {...input} {...otherProps}>
        {children}
      </TextInput>
      {touched && error ? (
        <Span color="red" size="sm">
          {error}
        </Span>
      ) : null}
    </FormControl>
  );
};

const TextAreaInput = styled(BaseInput)`
  max-height: 20rem;
  resize: vertical;
`;

export const CustomTextArea = (props) => {
  const { input, meta, children, label, entity, ...otherProps } = props;
  const { touched, error } = meta;
  return (
    <FormControl>
      {label && <Label text={label} entity={entity} />}
      <TextAreaInput as="textarea" {...input} {...otherProps}>
        {children}
      </TextAreaInput>
      {touched && error ? (
        <Span color="red" size="sm">
          {error}
        </Span>
      ) : null}
    </FormControl>
  );
};

const SelectInput = styled(BaseInput)`
  option {
    background: black;
  }
`;

export const CustomSelect = (props) => {
  const { input, children, label, ...otherProps } = props;
  return (
    <>
      {label && <Label text={label} />}
      <SelectInput as="select" {...input} {...otherProps}>
        {children}
      </SelectInput>
    </>
  );
};
