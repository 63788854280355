import { Container, Grid, Section } from "../../components/layout";
import { Main, PageHeader } from "../../components/layout";

import AboutCareer from "./AboutCareer";
import AboutFeaturedClients from "./AboutFeaturedClients";
import AboutServices from "./AboutServices";
import { Meta } from "../../components/meta";
import { Text } from "../../components/typography";
import { Title } from "../../components/typography";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";

const AboutPage = () => {
  return (
    <>
      <Meta
        title="About"
        description="Agency focused on helping our clients and partners achieve
        their business and personal goals."
      />
      <PageHeader
        title="About"
        sectionHeader="We help business reach their goals through beautiful web experiences."
      >
        <Text width={60} color="gray"></Text>
      </PageHeader>
      <Main>
        <BaseContent background="black">
          <Container width={60}>
            <Text>
              Founded in 2019, Edaston is a small, but highly experienced web
              design and development agency based in Laurel, Maryland. While we
              serve clients nationwide, we hold a special place in our hearts
              for the vibrant Washington D.C. metro area - Maryland, Virginia,
              and Washington, D.C. We understand the unique needs of local
              businesses and can tailor our services to meet your specific
              goals.
            </Text>
            <Text>
              We offer a comprehensive suite of web design and development
              services, including website creation and maintenance, SEO
              optimization, user experience testing, and strategic planning. Our
              focus extends beyond just building a website - we partner with you
              to create a powerful online tool that reflects your brand
              identity, resonates with your target audience, and propels your
              business forward.
            </Text>
          </Container>
          <Container width={60}>
            <Grid>
              <Title>Captivate your audience</Title>
              <Text>
                Our modern and user-friendly designs will grab attention and
                leave a lasting impression.
              </Text>
            </Grid>
          </Container>
        </BaseContent>
        <AboutServices />
        <BaseContent>
          <Container width={80}>
            <Title number="3">Our Mission</Title>
            <Text>
              At Edaston, our mission is to empower small businesses like yours
              to achieve online success. We're a passionate team of web design
              and development experts, dedicated to creating custom,
              high-performing websites that:
            </Text>
            <br />
            <Grid col={3}>
              <div>
                <Title number="6">Captivate your audience</Title>
                <Text>
                  Our modern and user-friendly designs will grab attention and
                  leave a lasting impression.
                </Text>
              </div>
              <div>
                <Title number="6">Simplify your life</Title>
                <Text>
                  We take care of everything, from design and development to SEO
                  and maintenance, freeing you to focus on what you do best –
                  running your business.
                </Text>
              </div>
              <div>
                <Title number="6">Drive results</Title>
                <Text>
                  We'll equip you with the tools to attract new leads, convert
                  them into customers, and ultimately achieve your business
                  goals.
                </Text>
              </div>
            </Grid>
          </Container>
        </BaseContent>
        <AboutFeaturedClients />
        <AboutCareer />
      </Main>
    </>
  );
};

export default AboutPage;

const BaseContent = styled(Section)`
  display: grid;
  gap: 2rem;

  h5 {
    margin-top: 1rem;
  }

  ul {
    margin-top: 1rem;

    li {
      list-style: circle;
      margin-left: 2rem;
      margin-bottom: 0.5rem;

      span {
        font-weight: bold;
      }
    }
  }
  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;
