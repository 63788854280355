import { Link } from "react-router-dom";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const AppLinks = ({ to, children, ...otherProps }) => {
  return (
    <BaseStyle to={to} {...otherProps}>
      {children}
    </BaseStyle>
  );
};

export default AppLinks;

const BaseStyle = styled(Link)`
  border-bottom: 1px solid transparent;
  transition: all 1s ease-in;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid ${colors.lightblue};
  }
`;
