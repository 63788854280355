export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_CREATE = "CONTACT_CREATE";
export const CONTACT_ERROR = "CONTACT_ERROR";

export const SUBSCRIBE_REQUEST = "SUBSCRIBE_REQUEST";
export const SUBSCRIBE_CREATE = "SUBSCRIBE_CREATE";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";

export const CAPABILITY_REQUEST = "CAPABILITY_REQUEST";
export const CAPABILITY_CREATE = "CAPABILITY_CREATE";
export const CAPABILITY_ERROR = "CAPABILITY_ERROR";

export const START_PROJECT_REQUEST = "START_PROJECT_REQUEST";
export const START_PROJECT_CREATE = "START_PROJECT_CREATE";
export const START_PROJECT_ERROR = "START_PROJECT_ERROR";
