import styled, { css } from "styled-components";

import { colors } from "../../assets/styles/parameters";

const Span = ({ color, children }) => {
  return <SpanStyle color={color}>{children}</SpanStyle>;
};

export default Span;

const SpanStyle = styled.span`
  margin-bottom: 1rem;

  ${({ color }) => {
    switch (color) {
      case "white":
        return css`
          color: ${colors.white};
        `;
      case "gold":
        return css`
          color: ${colors.gold};
        `;
      case "gray":
        return css`
          color: ${colors.gray600};
        `;
      default:
        return css`
          color: ${colors.black};
        `;
    }
  }}
`;
