import { Cards } from "../../components/cards";
import { Component } from "react";
import { FetchServices } from "../../redux/service/ServiceActions";
import { Grid } from "../../components/layout";
import { Loader } from "../../components/loader";
import { connect } from "react-redux";

class ServicesList extends Component {
  componentDidMount() {
    this.props.FetchServices();
  }

  render() {
    const { loading, services } = this.props.service;

    if (loading || !services) {
      return <Loader status={loading} />;
    }

    return (
      <Grid col={3}>
        {services.map(({ title, slug }) => {
          return (
            <Cards
              key={slug}
              version={1}
              title={title}
              featuredText={title}
              path={`/services/${slug}`}
            />
          );
        })}
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return { service: state.service };
};

export default connect(mapStatetoProps, { FetchServices })(ServicesList);
