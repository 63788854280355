import { colors, responsiveness } from "../../../assets/styles/parameters";
import styled, { css } from "styled-components";

const Section = ({ background, children, ...otherProps }) => {
  return (
    <BaseSection background={background} {...otherProps}>
      {children}
    </BaseSection>
  );
};

export default Section;

export const BaseSection = styled.section`
  padding: 6rem 4rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 3rem 1rem;
  }

  ${({ background }) => {
    switch (background) {
      case "black":
        return css`
          background-color: ${colors.black};
          * {
            color: ${colors.white};
          }
        `;
      default:
        return css`
          background-color: ${colors.white};
        `;
    }
  }}
`;
