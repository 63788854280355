import TagManager from "react-gtm-module";

const GTMID = "GTM-P737K45";

export const useGoogleAnalytics = () => {
  TagManager.initialize({
    gtmId: GTMID,
  });

  // useEffect(() => {
  //   TagManager.initialize({
  //     gtmId: GTMID,
  //   });
  // }, []);
};

// export const useGAPageView = () => {
//   const location = useLocation();

//   useEffect(() => {
//     window.gtag("event", "page_view", {
//       page_path: location.pathname + location.search + location.hash,
//       page_search: location.search,
//       page_hash: location.hash,
//     });
//   }, [location]);
// };
