import { CustomTextArea, Input } from "../../components/forms";
import { Field, Form } from "react-final-form";

import { Button } from "../../components/button";
import { Component } from "react";
import { CreateContact } from "../../redux/contact/ContactActions";
import { Grid } from "../../components/layout";
import { connect } from "react-redux";

class ContactForm extends Component {
  onSubmitValues = (values) => {
    this.props.CreateContact(values);
  };

  render() {
    return (
      <>
        <Form
          onSubmit={this.onSubmitValues}
          render={({ handleSubmit, submitting, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Grid>
                <div>
                  <Field
                    name="first_name"
                    type="text"
                    component={Input}
                    label="First Name"
                    placeholder="My first name is..."
                  />
                </div>
                <div>
                  <Field
                    name="last_name"
                    type="text"
                    component={Input}
                    label="Last Name"
                    placeholder="My last name is..."
                  />
                </div>
              </Grid>
              <Grid>
                <div>
                  <Field
                    name="phone"
                    type="tel"
                    component={Input}
                    label="Phone Number"
                    placeholder="You can call you on..."
                  />
                </div>
                <div>
                  <Field
                    name="email"
                    type="email"
                    component={Input}
                    label="Email"
                    placeholder="You can email me at..."
                  />
                </div>
              </Grid>
              <Field
                name="message"
                type="text"
                component={CustomTextArea}
                label="Message"
                placeholder="Enter your message ..."
              />
              <Button
                type="submit"
                disabled={submitting || pristine || invalid}
              >
                {pristine ? "Complete Form" : "Submit Form"}
                {submitting && "Submitting"}
              </Button>
            </form>
          )}
        />
      </>
    );
  }
}

export default connect(null, { CreateContact })(ContactForm);
