import { GridGap, responsiveness } from "../../../assets/styles/parameters";

import styled from "styled-components";

const Grid = ({ col, children, ...otherProps }) => {
  switch (col) {
    case 5:
      return <Grid5 {...otherProps}>{children}</Grid5>;
    case 4:
      return <Grid4 {...otherProps}>{children}</Grid4>;
    case 3:
      return <Grid3 {...otherProps}>{children}</Grid3>;
    case 6:
      return <GridCase {...otherProps}>{children}</GridCase>;
    case 7:
      return <GridCase2 {...otherProps}>{children}</GridCase2>;
    default:
      return <Grid2 {...otherProps}>{children}</Grid2>;
  }
};

export default Grid;

const BaseStyle = styled.div`
  display: grid;
  gap: ${GridGap.default};
  margin-top: 1rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    gap: 0;
  }
`;

const GridCase2 = styled(BaseStyle)`
  grid-template-columns: 0.5fr 1fr;
`;

const GridCase = styled(BaseStyle)`
  grid-template-columns: 1fr 0.4fr;
`;

const Grid5 = styled(BaseStyle)`
  grid-template-columns: repeat(5, 1fr);
`;

const Grid4 = styled(BaseStyle)`
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (min-width: ${responsiveness.ipadMin}) and (max-width: ${responsiveness.ipadMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Grid3 = styled(BaseStyle)`
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (min-width: ${responsiveness.ipadMin}) and (max-width: ${responsiveness.ipadMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Grid2 = styled(BaseStyle)`
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;
