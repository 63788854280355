import {
  CASES_FETCH,
  CASE_ERROR,
  CASE_FETCH,
  CASE_REQUEST,
  CLIENTS_FETCH,
  CLIENT_ERROR,
  CLIENT_FETCH,
  CLIENT_REQUEST,
} from "./CaseTypes";

export const caseReducer = (state = {}, action) => {
  switch (action.type) {
    case CASE_REQUEST:
      return { ...state, loading: true };
    case CASES_FETCH:
      return { ...state, loading: false, cases: action.payload };
    case CASE_FETCH:
      return { ...state, loading: false, case: action.payload };
    case CASE_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_REQUEST:
      return { ...state, loading: true };
    case CLIENTS_FETCH:
      return { ...state, loading: false, clients: action.payload };
    case CLIENT_FETCH:
      return { ...state, loading: false, client: action.payload };
    case CLIENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
