import { Container, Grid, Section } from "../../components/layout";

import { Component } from "react";
import { FetchClients } from "../../redux/cases/CaseActions";
import { Title } from "../../components/typography";
import { connect } from "react-redux";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";

class AboutFeaturedClients extends Component {
  componentDidMount() {
    this.props.FetchClients();
  }

  render() {
    const { clients } = this.props;

    if (clients.loading) {
      return <Section>Loading...</Section>;
    }

    console.log(clients);
    return (
      <>
        {clients.clients && clients.clients.length > 0 && (
          <Section>
            <Container width={80}>
              <Title number="4">Some of our partners</Title>
              <Grid col={4}>
                {clients.clients.map(({ name, logo }, id) => {
                  return (
                    <Cont key={id}>
                      {logo && <img src={logo} alt={`${name} logo`} />}
                    </Cont>
                  );
                })}
              </Grid>
            </Container>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { clients: state.clients };
};

export default connect(mapStateToProps, { FetchClients })(AboutFeaturedClients);

const Cont = styled.div`
  margin-top: 2rem;
  height: 150px;
  text-align: center;

  img {
    height: 100%;
    object-fit: contain;
    color: black;
    filter: grayscale(100%);
  }

  p {
    /* white-space: nowrap; */
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 90%;
  }
`;
