import {
  colors,
  headings,
  responsiveness,
} from "../../../assets/styles/parameters";

import NavLinkItem from "./NavigationItem";
import { OrgInfo } from "../../../utils/StaticOrgInfo";
import styled from "styled-components";

const NavigationLink = ({ paths, open, ...otherProps }) => {
  return (
    <NavLinks>
      <NavLinkList open={open} {...otherProps}>
        <div>
          {paths.map((path, index) => {
            return (
              <NavLinkItem key={index} name={path.name} path={path.path} />
            );
          })}
        </div>
        <div>
          <a href={`tel:+${OrgInfo.phone}`}>{OrgInfo.phone}</a>
          <NavLinkItem
            highlight="true"
            name="Start Your Project"
            path="/start"
          />
        </div>
      </NavLinkList>
    </NavLinks>
  );
};

export default NavigationLink;

const NavLinkList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid transparent;

  div {
    display: flex;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: ${({ open }) => (open ? "flex" : "none")};
    flex-direction: column;
    background-color: ${colors.white};
    z-index: 10000;
    height: 90vh;
    padding-bottom: 3rem;
    align-items: center;
    border-top: 1px solid black;

    div {
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
      width: 100%;

      li,
      a {
        font-size: ${headings.h4};
      }

      &:last-child {
        flex-direction: column-reverse;

        li {
          margin-bottom: 2rem;
        }
      }
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid transparent;

  .active {
    border-bottom: 1px solid ${colors.black};
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    flex-direction: column;
  }
`;
