import {
  colors,
  fontFamily,
  fontSize,
  headings,
  lineHeight,
} from "./parameters";

import { createGlobalStyle } from "styled-components";
import img4 from "./../imgs/Asset-11.png";

export const GlobalStyles = createGlobalStyle`
*, 
*::before, 
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1.2;
}

html{
    font-size: ${fontSize.def};
    scroll-behavior: smooth;

}

body{
    background: ${colors.white};
    font-family: ${fontFamily.body};
    line-height: ${lineHeight.body};
    font-weight: 200;  
    background: url(${img4}) no-repeat;
    background-size: 2500px;
    background-attachment: fixed;

    &::-webkit-scrollbar {
        width: 0.1px;             
    }

    &::-webkit-scrollbar-track, 
    &::-webkit-scrollbar-thumb{
        background: transparent; 
    }
}

  
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1rem;
    font-family: ${fontFamily.headings};
    line-height: ${lineHeight.heading};
    font-weight: 400;
  }
  
  h1{font-size: ${headings.h1}}
  h2{font-size: ${headings.h2}}
  h3{font-size: ${headings.h3}}
  h4{font-size: ${headings.h4}}
  h5{font-size: ${headings.h5}}
  h6{font-size: ${headings.h6}}
  

a{
    text-decoration: none;
    color: ${colors.black}
}

ol, li, ul{
    list-style: none;
}


img{
    width: 100%;
}

select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;   
}

`;
