import { colors, fontSize, shadow } from "../../assets/styles/parameters";
import { useEffect, useState } from "react";

import { Button } from "../button";
import { Text } from "../typography";
import styled from "styled-components";

export const Cookie = () => {
  const [show, setShow] = useState(true);

  const onHandleShow = () => {
    setShow(false);

    localStorage.setItem(
      "Edaston-Cookie-Accept",
      "By using Edaston, I agree to its privacy policy."
    );
  };

  useEffect(() => {
    if (localStorage.getItem("Edaston-Cookie-Accept")) {
      setShow(false);
    }
  }, [show]);

  return (
    <BaseContainer show={show}>
      <div>
        <Text scale="sm">
          We use analytics to improve your browsing experience.
          <a href="/policy" target="_blank">
            {" "}
            legal - Terms of Use and Privacy
          </a>
          .
        </Text>
      </div>
      <Button onClick={onHandleShow}>Accept</Button>
    </BaseContainer>
  );
};

export default Cookie;

const BaseContainer = styled.div`
  cursor: pointer;
  background: ${colors.white};
  box-shadow: ${shadow.dark};
  padding: 0.4rem;
  font-size: ${fontSize.small};
  display: ${({ show }) => {
    return show ? "flex" : "none";
  }};

  flex-direction: space-between;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  transition: all 1s ease;
  margin: auto;

  div {
    margin-right: 1rem;

    p {
      padding: 0;
      margin: 0;
    }
  }

  & > button {
    font-size: ${fontSize.small} !important;
    padding: 0.3rem 1rem;
    margin: 0;
  }
`;
