import { Text, Title } from "../../typography";

import { Button } from "../../button";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

const Header = () => {
  return (
    <BaseHeader>
      <HeroContent>
        <Title number="1">
          Your Guide to Building a Thriving Online Presence.
        </Title>
        <Text>Get Found, Get Leads, Get Results!</Text>
        <HeroBtnContainer>
          <Button type="link" to="/works">
            See our work
          </Button>
          <Button type="link" to="/start" varient="black_outline">
            Start project
          </Button>
        </HeroBtnContainer>
      </HeroContent>
    </BaseHeader>
  );
};

export default Header;

const BaseHeader = styled.header`
  height: 100vh;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    text-align: left;
    padding: 0 1rem;
    align-items: flex-end;
  }
`;

const HeroContent = styled.div`
  width: 70%;
  margin: auto;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
  }
`;

const HeroBtnContainer = styled.div`
  @media screen and (max-width: ${responsiveness.mobile1}) {
    display: flex;
  }
`;
