import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const CheckboxInput = ({ name }) => {
  return (
    <BaseLabel>
      <Text>{name}</Text>
      <input type="checkbox" />
      <span />
    </BaseLabel>
  );
};

const BaseLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: ${colors.gray400};
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid ${colors.white};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ span {
    background-color: ${colors.blue};
  }

  input:checked ~ span {
    background-color: ${colors.blue};
  }

  input:checked ~ span:after {
    display: block;
  }
`;
