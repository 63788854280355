import {
  Container,
  Grid,
  Main,
  PageHeader,
  Section,
} from "../../components/layout";

import { FetchTerms } from "../../redux/terms/TermsActions";
import { Link } from "react-router-dom";
import { Meta } from "../../components/meta";
import { Text } from "../../components/typography";
import { connect } from "react-redux";
import { useEffect } from "react";

const LegalPage = (props) => {
  const { FetchTerms, Terms } = props;

  useEffect(() => {
    FetchTerms();
  }, [FetchTerms]);

  return (
    <>
      <Meta title="Respecting your privacy" />
      <PageHeader
        title="Terms and Privacy"
        sectionHeader="Respecting your privacy"
      >
        <Text>
          We respect and are committed to preserving the privacy of our users.
        </Text>
      </PageHeader>
      <Main>
        <Section>
          <Container>
            {Terms.loading || !Terms.terms ? (
              <Text>Loading</Text>
            ) : (
              <Grid>
                {Terms.terms.map(({ title, slug, id }) => {
                  return (
                    <Link key={id} to={`/policy/${slug}`} state={{ id: id }}>
                      View our {title}
                    </Link>
                  );
                })}
              </Grid>
            )}
          </Container>
        </Section>
      </Main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Terms: state.legal,
  };
};

export default connect(mapStateToProps, { FetchTerms })(LegalPage);
