export const MainPaths = [
  {
    name: "works",
    path: "/works",
  },
  {
    name: "about",
    path: "/about",
  },
  // {
  //   name: "Blog",
  //   path: "/blog",
  // },
  {
    name: "contact",
    path: "/contact",
  },
];

export const FooterPaths = [
  // {
  //   name: "Careers",
  //   path: "/careers",
  // },
];
