import { colors } from "../../../assets/styles/parameters";
import styled from "styled-components";

const SubNavigation = ({ data, setActive }) => {
  return (
    <NavigationSub>
      <ul>
        {data.map(({ title }) => {
          return (
            <li key={title} onClick={setActive}>
              {title}
            </li>
          );
        })}
      </ul>
    </NavigationSub>
  );
};

export default SubNavigation;

const NavigationSub = styled.nav`
  background-color: ${colors.white};
  padding: 0 5rem;
  position: sticky;
  width: 100%;

  ul {
    display: flex;
    z-index: 100;

    li {
      padding-right: 1rem;

      &:hover {
        cursor: pointer;
      }

      .active {
        font-weight: bold;
        border-bottom: 1px solid black;
      }
    }
  }
`;
