import { Container, Main, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import CapabilityForm from "./CapabilityForm";
import { Meta } from "../../components/meta";
import { useGoogleAnalytics } from "../../utils/GoogleAnalytics";

const Capability = () => {
  return (
    <>
      <Meta
        title="Capability Deck"
        description="Page to request our capability deck, which includes information about our business, services, and our previous works."
      />
      <Main>
        <Section>
          <Container width="70" margin="auto">
            <Title>Send me your capability deck!</Title>
            <Text>
              Our team will prepare our a capability deck for you to give you
              more insight into our work.
            </Text>
            <CapabilityForm />
          </Container>
        </Section>
      </Main>
    </>
  );
};

export default Capability;
