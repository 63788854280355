import {
  CAPABILITY_CREATE,
  CAPABILITY_ERROR,
  CAPABILITY_REQUEST,
  CONTACT_CREATE,
  CONTACT_ERROR,
  CONTACT_REQUEST,
  START_PROJECT_CREATE,
  START_PROJECT_ERROR,
  START_PROJECT_REQUEST,
  SUBSCRIBE_CREATE,
  SUBSCRIBE_ERROR,
  SUBSCRIBE_REQUEST,
} from "./ContactTypes";

import { toast } from "react-toastify";

export const ContactReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_REQUEST:
      return { ...state, loading: true };
    case CONTACT_CREATE:
      toast.success("Message Sent Successfully", {
        onClose: () =>
          setTimeout(() => {
            window.location.reload();
          }, 5000),
      });
      return { ...state, loading: false, contact: action.payload };
    case CONTACT_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SubscribeReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_REQUEST:
      return { ...state, loading: true };
    case SUBSCRIBE_CREATE:
      toast.success("Subscribed Successfully", {
        onClose: () =>
          setTimeout(() => {
            window.location.reload();
          }, 5000),
      });
      return { ...state, loading: false, subscribe: action.payload };
    case SUBSCRIBE_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const CapabilityReducer = (state = {}, action) => {
  switch (action.type) {
    case CAPABILITY_REQUEST:
      return { ...state, loading: true };
    case CAPABILITY_CREATE:
      toast.success("Request Sent Successfully", {
        onClose: () =>
          setTimeout(() => {
            window.location.replace("/works");
          }, 5000),
      });
      return { ...state, loading: false, lead: action.payload };
    case CAPABILITY_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const StartProjectReducer = (state = {}, action) => {
  switch (action.type) {
    case START_PROJECT_REQUEST:
      return { ...state, loading: true };
    case START_PROJECT_CREATE:
      toast.success("Request Sent Successfully", {
        onClose: () =>
          setTimeout(() => {
            window.location.replace("/works");
          }, 5000),
      });
      return { ...state, loading: false, start: action.payload };
    case START_PROJECT_ERROR:
      toast.error("Something Bad Happened");
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
