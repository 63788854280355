import { headings, responsiveness } from "../../assets/styles/parameters";

import styled from "styled-components";

const Title = ({ number, children, ...otherProps }) => {
  switch (number) {
    case "1":
      return <First {...otherProps}>{children}</First>;
    case "2":
      return <Second {...otherProps}>{children}</Second>;
    case "3":
      return <Third {...otherProps}>{children}</Third>;
    case "4":
      return <Fourth {...otherProps}>{children}</Fourth>;
    case "5":
      return <Fifth {...otherProps}>{children}</Fifth>;
    case "6":
      return <Sixth {...otherProps}>{children}</Sixth>;
    default:
      return <Third {...otherProps}>{children}</Third>;
  }
};

export default Title;

const First = styled.h1`
  font-size: ${headings.h2};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    font-size: ${headings.h2};
  }
`;

const Second = styled.h2`
  font-size: ${headings.h2};
`;

const Third = styled.h3`
  font-size: ${headings.h3};
`;

const Fourth = styled.h4`
  font-size: ${headings.h4};
`;

const Fifth = styled.h5`
  font-size: ${headings.h5};
`;

const Sixth = styled.h6`
  font-size: ${headings.h6};
`;
