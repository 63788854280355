import { GridGap, responsiveness } from "../../../assets/styles/parameters";

import styled from "styled-components";

export const Col2 = ({ children, ...otherProps }) => {
  return <BaseLayout2 {...otherProps}>{children}</BaseLayout2>;
};

const BaseLayout2 = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: ${GridGap.default};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

export const Col3 = ({ children, ...otherProps }) => {
  return <BaseLayout3 {...otherProps}>{children}</BaseLayout3>;
};

const BaseLayout3 = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: ${GridGap.default};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

export const Col3B = ({ children, ...otherProps }) => {
  return <BaseLayout3B {...otherProps}>{children}</BaseLayout3B>;
};

const BaseLayout3B = styled.div`
  display: grid;
  grid-template-columns: 0.35fr 1fr 0.35fr;
  gap: ${GridGap.default};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;
