import { Col3, Container, Grid, Main, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";
import {
  colors,
  fontSize,
  responsiveness,
  shadow,
} from "../../assets/styles/parameters";
import { useDispatch, useSelector } from "react-redux";

import { FetchCase } from "../../redux/cases/CaseActions";
import { Meta } from "../../components/meta";
import parse from "html-react-parser";
import styled from "styled-components";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const CaseDetailPage = () => {
  const { works } = useSelector((state) => state);
  let params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchCase(params.slug));
  }, [dispatch, params.slug]);

  return (
    <>
      {works.case && (
        <>
          <Meta
            title={`${works.case.title} | case studies`}
            description="Some of our recent projects."
          />
          <CaseHeader background={works.case.background}>
            <Title number="1">{works.case.title}</Title>
            <Grid>
              {parse(works.case.services, {
                replace: (domNode) => {
                  if (domNode.type === "tag") {
                    return <Text>{domNode.children[0].data}</Text>;
                  }
                },
              })}

              <Text>{works.case.headline}</Text>
            </Grid>
          </CaseHeader>
          <Main>
            <Section>
              <FeaturedImage src={works.case.featured_image} alt="" />
            </Section>
            {works.case.body && (
              <Section>
                <Container>
                  <Col3>
                    <Title>Challenge</Title>
                    <div>
                      {parse(works.case.body)}
                      <br />
                      <br />
                      {parse(works.case.featured_quote)}
                    </div>
                  </Col3>
                </Container>
              </Section>
            )}
            <Section background="black">
              <Container width="70">
                {works.case.image_1 && (
                  <img src={works.case.image_1} alt={works.case.image_1_alt} />
                )}
              </Container>
            </Section>
            {works.case.results && (
              <Section>
                <Container>
                  <Col3>
                    <Title>Results</Title>
                    <div>{parse(works.case.results)}</div>
                  </Col3>
                </Container>
              </Section>
            )}
            <Section>
              <Container>
                <BaseResults>
                  {works.case.image_2 && (
                    <img
                      src={works.case.image_2}
                      alt={works.case.image_2_alt}
                    />
                  )}
                  {works.case.image_3 && (
                    <img
                      src={works.case.image_3}
                      alt={works.case.image_3_alt}
                    />
                  )}
                  {works.case.image_4 && (
                    <img
                      src={works.case.image_4}
                      alt={works.case.image_4_alt}
                    />
                  )}
                  {works.case.image_5 && (
                    <img
                      src={works.case.image_5}
                      alt={works.case.image_5_alt}
                    />
                  )}
                </BaseResults>
              </Container>
            </Section>
          </Main>
        </>
      )}
    </>
  );
};

export default CaseDetailPage;

const CaseHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${({ background }) => `#${background}`};
  height: 100vh;
  padding: 4rem 4rem;

  * {
    color: ${colors.white};
  }

  h1 {
    font-size: ${fontSize.h2};
    width: 80%;
  }

  & > div {
    width: 80%;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 2rem 1rem;

    h1 {
      width: 100%;
    }

    & > div {
      width: 100%;
    }
  }
`;

const FeaturedImage = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const BaseResults = styled.div`
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  img {
    box-shadow: ${shadow.dark};
    margin: 2rem;
    width: 30%;
    height: 100%;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    img {
      width: 80%;
    }
  }
`;
