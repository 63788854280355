import { Text, Title } from "../typography";
import { responsiveness, shadow } from "../../assets/styles/parameters";

import { AppLinks } from "../links";
import { Button } from "../button";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const CaseCard = ({
  card,
  id,
  title,
  headline,
  slug,
  image,
  imageDescription,
}) => {
  switch (card) {
    case 1:
      return (
        <BaseCaseCardV1>
          <div>
            <Text>Case Study</Text>
            <img src={image} alt={imageDescription} />
          </div>
          <div>
            <Title number="4">{title}</Title>
            <Text>{headline}</Text>
            <Button type="link" to={`/works/${slug}`} varient="primary-outline">
              View this project
            </Button>
          </div>
        </BaseCaseCardV1>
      );
    case 2:
      return (
        <BaseCaseCardV2>
          <img src={image} alt={imageDescription} />
          <div>
            <div>
              <Text>Case Study</Text>
              <Title number="4">{title}</Title>
            </div>
            <div>
              <Text>{headline}</Text>
              <Button
                type="link"
                to={`/works/${slug}`}
                varient="primary-outline"
              >
                View this project
              </Button>
            </div>
          </div>
        </BaseCaseCardV2>
      );
    case 3:
      return (
        <BaseCaseCardV3 img={image}>
          <div>
            <Text>Case Study</Text>
            <Title number="4">{title}</Title>
            <Text>{headline}</Text>
            <AppLinks type="link" to={`/works/${slug}`}>
              View Project
            </AppLinks>
          </div>
        </BaseCaseCardV3>
      );
    case 4:
      return (
        <BaseCaseCardV4 img={image}>
          <div>
            <Title number="4">{title}</Title>
            <AppLinks type="link" to={`/works/${slug}`}>
              View Project
            </AppLinks>
          </div>
        </BaseCaseCardV4>
      );
    default:
      return (
        <BaseCaseCardV2>
          <img src={image} alt={imageDescription} />
          <div>
            <div>
              <Text>Case Study</Text>
              <Title number="4">{title}</Title>
            </div>
            <div>
              <Text>{headline}</Text>
              <Button
                type="link"
                to={`/works/${slug}`}
                varient="primary-outline"
              >
                View this project
              </Button>
            </div>
          </div>
        </BaseCaseCardV2>
      );
  }
};

export default CaseCard;

const BaseCaseCardV1 = styled.div`
  padding: 2rem 0;

  & > div:nth-child(2) {
    width: 60%;

    h4 {
      margin-top: 1rem;
    }
  }

  img {
    height: 90vh;
    object-fit: cover;
    border-radius: 5px;
    border-radius: 10px;
    box-shadow: ${shadow.light};
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    & > div:nth-child(2) {
      width: 100%;
    }
  }
`;

const BaseCaseCardV2 = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem 0;
  margin-bottom: 2rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
  }

  h4 {
    margin: 1rem 0 0 0;
  }

  img {
    height: 70vh;
    object-fit: cover;
    border-radius: 5px;
    border-radius: 10px;
    box-shadow: ${shadow.light};
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    flex-direction: column;

    div {
      width: 100%;
      margin: 1rem 0;
      justify-content: flex-start;
    }
  }
`;

const BaseCaseCardV3 = styled.div`
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 10px;    

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    z-index: 1;    border-radius: 10px;

  }

  div {
    width: 70%;
    height: 50vh;
    z-index: 2;

    *{
      color: ${colors.white};
    }

    h4 {
      margin-top: 1rem;
    }
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    padding: 3rem 2rem;

    div {
    display: flex;   
    flex-direction: column;
    justify-content: flex-end;
    height: 70vh;     
    width: 100%;

  }
`;

const BaseCaseCardV4 = styled.div`
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  padding: 2rem;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 10px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    z-index: 1;
    border-radius: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50vh;
    z-index: 2;

    * {
      color: ${colors.white};
    }

    h4 {
      margin-bottom: 1rem;
    }
  }
`;
