import {
  TERMS_FETCH,
  TERM_ERROR,
  TERM_FETCH,
  TERM_REQUEST,
} from "./TermsTypes";

export const TermsReducer = (state = {}, action) => {
  switch (action.type) {
    case TERM_REQUEST:
      return { ...state, loading: true };
    case TERMS_FETCH:
      return { ...state, loading: false, terms: action.payload };
    case TERM_FETCH:
      return { ...state, loading: false, term: action.payload };
    case TERM_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
