import CaseList from "./CaseList";
import { Main } from "../../components/layout";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";

const CaseStudiesPage = () => {
  return (
    <>
      <Meta title="Case Studies" description="Some of our recent projects." />
      <PageHeader
        title="Featured Works"
        sectionHeader="Proven Results. See how we can help you build a thriving presence online."
      />
      <Main>
        <CaseList />
      </Main>
    </>
  );
};

export default CaseStudiesPage;
