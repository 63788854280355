import { Button } from "../button";
import { Title } from "../typography";
import { colors } from "../../assets/styles/parameters";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const CallToAction = () => {
  const location = useLocation();
  return (
    <BaseCallToAction location={location}>
      <div>
        <Title number="3">Have a project in mind?</Title>
        <Button type="link" to="/start" varient="white_outline">
          Start project
        </Button>
      </div>
    </BaseCallToAction>
  );
};

export default CallToAction;

const BaseCallToAction = styled.section`
  display: ${({ location }) =>
    location.pathname === "/start" ? "none" : "block"};
  position: relative;
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 8rem 4rem;

  & > div {
    a {
      padding: 0;
    }

    @media screen and (min-width: ${responsiveness.desktop1}) {
      max-width: ${responsiveness.maxWidth};
      margin: auto;
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 4rem 1rem 4rem;
  }
`;
