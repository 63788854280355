import { border, colors } from "../../assets/styles/parameters";
import styled, { css } from "styled-components";

import { Link } from "react-router-dom";

export const Button = ({
  type,
  varient,
  size,
  path,
  children,
  ...otherProps
}) => {
  switch (type) {
    case "link":
      return (
        <StyledButton
          as={Link}
          type={type}
          varient={varient}
          size={size}
          {...otherProps}
          href={path}
        >
          {children}
        </StyledButton>
      );
    default:
      return (
        <StyledButton size={size} varient={varient} {...otherProps}>
          {children}
        </StyledButton>
      );
  }
};

const BaseButton = styled.button`
  &,
  &:link,
  &:visited {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border-radius: 5px;
    width: max-content;
    margin: 0.5rem 1rem 0 0;
    transition: 1s all ease;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.65;
    cursor: auto;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Varients = ({ varient }) => {
  switch (varient) {
    case "black_outline":
      return css`
        color: ${colors.black};
        background-color: ${colors.white};
        border: ${border.black};

        a {
          color: ${colors.black};
        }

        &:hover {
          background-color: ${colors.black};
          color: ${colors.white};
          border: ${border.black};

          a {
            color: ${colors.white};
          }
        }
      `;

    case "white_outline":
      return css`
        color: ${colors.white};
        border: ${border.transparent};

        a {
          color: ${colors.white};
        }

        &:hover {
          border-bottom: ${border.white};
          border-radius: 0;
        }
      `;
    default:
      return css`
        background: ${colors.black};
        color: ${colors.white};
        border: ${border.transparent};

        a,
        p {
          color: ${colors.white};
        }

        &:hover {
          background-color: ${colors.white};
          color: ${colors.black};
          border: ${border.black};

          a,
          p {
            color: ${colors.black};
          }
        }
      `;
  }
};

const Sizes = ({ size }) => {
  switch (size) {
    case "large":
      return css`
        padding: 0.7rem 2rem;
      `;

    case "medium":
      return css`
        padding: 0.6rem 2rem;
      `;

    case "small":
      return css`
        padding: 0.4rem 2rem;
      `;
    default:
      return css`
        padding: 0.4rem 2rem;
      `;
  }
};

const StyledButton = styled(BaseButton)`
  ${Sizes}
  ${Varients}
`;
